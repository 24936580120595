.fixed {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 9999;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s all;
  transform: translateY(-10%);
}

.active {
  opacity: 1;
  pointer-events: unset;
  transform: translateY(0%);
}

.notificationContainer {
  border-radius: 16px;
  background: #FFF;
  box-shadow: 8px 13px 35px 4px rgba(59, 60, 61, 0.10);
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: max-content;
  position: relative;
}

.notificationContainer section {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.notificationContainer section div:first-child {
  color: var(--text-text-500, #1D2939);
  /* Text lg/medium */
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 155.556% */
}

.notificationContainer section div:last-child {
  color: var(--text-text-400, #667085);
  /* Text sm/regular */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}

.button {
  color: var(--text-text-500, #1D2939);
  /* Text xs/Medium */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: 1000px;
  border: 1px solid var(--border-border-500, #D0D5DD);
  background: var(--base-white, #FFF);
  padding: 4px 12px;
  width: max-content;
  margin-left: auto;
  cursor: pointer;
}

.button:hover {
  background: var(--background-background-3, #F9FAFB);
}

.closeButton {
  position: absolute;
  top: -8px;
  right: -8px;
  cursor: pointer;
}

@media screen and (max-width: 1100px) {
  .notificationContainer section div:first-child {
    font-size: 14px;
  }

  .notificationContainer section div:last-child {
    font-size: 12px;
  }

  .fixed {
    right: 16px;
    bottom: 10px;
  }
}