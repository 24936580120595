.header {
  backdrop-filter: saturate(180%) blur(5px);
  background: hsla(0,0%,100%,.8);
  padding: 20px 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  top: 0;
  left: 0;
  transition: 0.5s all;
}

.logo {
  position: relative;
  height: 50px;
  width: 189px;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  display: flex;
  gap: 12px;
}

.buttons a div {
  padding: 10px 18px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  cursor: pointer;
  border-radius: 1000px;
  border: 1px solid transparent;
}

.buttons a:first-child div {
  color: var(--text-text-500, #1D2939);
  border: 1px solid transparent;
  background: transparent;
}

.buttons a:last-child div {
  border: 1px solid var(--primary-primary-500, #0D715F);
  background: var(--primary-primary-500, #0D715F);
  color: var(--base-white, #FFF);
}

.listMenu {
  display: flex;
  gap: 32px;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  color: var(--text-text-400, #667085);
  /* Text md/Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  position: relative;
}

.menuItemDropdown {
  position: absolute;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;

  border-radius: 8px;
  border: 1px solid var(--border-border-400, #EAECF0);
  background: var(--background-background-1, #FCFAF4);
  /* Shadow/lg */
  box-shadow: 0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08);
  width: max-content;
  z-index: 10000;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: 0.5s all;
  max-width: 348px;
}

.menuItemDropdown section {
  display: flex;
  align-items: center;
  gap: 12px;
}

.menuItemDropdown section span:first-child {
  color: var(--text-text-500, #1D2939);
  /* Text md/Bold */
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  margin-bottom: 3px;
}

.menuItemDropdown section span:last-child {
  color: var(--text-text-500, #1D2939);
  /* Text xs/regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
}

.dropdownTranslate {
  padding: 8px;
  min-width: 123px;
  border-radius: 8px;
  gap: 0px;
}

.dropdownTranslate section {
  padding: 12px 0;
  gap: 6px;
}

.dropdownTranslate div {
  color: var(--text-text-500, #1D2939);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.dropdownTranslate div:first-child {
  border-style: solid;
  border-radius: 100%;
}

.arrow {
  transition: 0.2s all;
}

.menuItem:hover .arrow {
  transform: rotate(180deg);
}

.menuItemDropdown {
  pointer-events: none;
}

.dropdown::before {
  display: block;
  content: '';
  height: 10px;
  position: absolute;
  bottom: -10px;
  width: 100%;
  left: 0;
}

.dropdown:hover .menuItemDropdown {
  opacity: 1;
  pointer-events: unset;
}

.menuItemActive {
  color: #0D715F;
  font-weight: 700;
}

.menu {
  display: none;
}

.sideMenu {
  position: fixed;
  top: 72px;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #fff;
  display: none;
  overflow: auto;
}

.buttonLogin {
  padding: 24px 16px;
  border-top: 1px solid var(--border-border-500, #D0D5DD);
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
}

.buttonLogin div {
  width: 100%;
  text-align: center;
  padding: 10px 18px;
  border-radius: 1000px;
}

@media screen and (max-width: 1100px) {
  .header {
    background-color: #fff;
  }

  .logo {
    height: 32px;
    width: 121px;
  }

  .sideMenu {
    display: block;
  }

  .listMenu, .buttons {
    display: none;
  }

  .menu {
    display: block;
  }

  .menuItem {
    padding: 12px 16px;
    color: var(--gray-900, #101828);
    width: 100%;
    justify-content: space-between;
  }

  .menuItem {
    margin-bottom: 8px;
  }

  .menuItem:hover .arrow {
    transform: unset;
  }

  .menuItemDropdownMobile {
    height: 0px;
    transition: 0.3s all;
    overflow: hidden;
  }

  .menuItemDropdownMobile section {
    padding: 12px 26px;
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .menuItemDropdownMobile section div:first-child {
    color: var(--text-text-500, #1D2939);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
  }

  .menuItemDropdownMobile section div:last-child {
    color: var(--text-text-400, #667085);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .menuItemActive {
    color: var(--primary-primary-500, #0D715F);
    font-weight: 500;
  }

  .menuItemActive .arrow {
    transform: rotate(180deg) !important;
  }
  
  .buttonTranslate {
    padding: 12px 9px;
  }
}