.container {
  max-width: 1216px;
  margin: auto;
}

.button {
  display: flex;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 1000px;
  cursor: pointer;

  /* Text */
  color: var(--base-white, #FFF);
  /* Text md/Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  border: 1px solid var(--border-border-500, #D0D5DD);
  background: var(--base-white, #FFF);
}

.button {
  transition: 0.3s all;
}

.button:hover {
  background: var(--background-background-3, #F9FAFB);
}

.button:active {
  border: 1px solid var(--border-border-500, #D0D5DD);
  background: var(--base-white, #FFF);
}

.buttonPrimary {
  display: flex;
  padding: 11px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 1000px;
  border: 1px solid #0D715F;
  background: #0D715F;
  cursor: pointer;

  /* Text */
  color: var(--base-white, #FFF);
  /* Text md/Medium */
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.buttonNoBorder {
  transition: 0.3s all;
}

.buttonNoBorder:hover {
  background: var(--background-background-3, #F9FAFB) !important;
}

.buttonNoBorder:active {
  color: var(--text-text-300, #D0D5DD) !important;
}

.buttonPrimary {
  transition: 0.3s all;
}

.buttonPrimary:hover {
  border: 1px solid #075345 !important;
  background: #075345 !important;
}

.buttonPrimary:active {
  border: 1px solid #82D2C4 !important;
  background: #82D2C4 !important;
}

.iframe {
  display: block;
  border: none;
  height: 100vh;
  margin: auto;
  width: 90vw;
  max-width: 1000px;
  border-radius: 12px;
  overflow: hidden;
}

/* Form */

.rowForm {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.field, .fieldPhone {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  width: 100%;
}

.field label, .fieldPhone label {
  color: var(--text-text-500, #1D2939);
  /* Text sm/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.field input, .field textarea, .field .select {
  border-radius: 6px;
  border: 1px solid var(--border-border-500, #D0D5DD);
  background: var(--base-white, #FFF);
  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  outline: none;
  color: var(--text-text-500, #1D2939);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex;
  padding: 10px 14px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100% !important;
  resize: unset;
  font-family: 'Inter', sans-serif !important; 
  min-height: 46.5px;
  justify-content: space-between;
}

.field .select {
  cursor: pointer;
}

.field textarea {
  height: 120px;
}

.field input::placeholder, .field textarea::placeholder, .field select::placeholder {
  color: var(--text-text-400, #667085);
}

.submitBtn {
  border-radius: 1000px;
  background: var(--primary-primary-500, #0D715F);
  display: flex;
  min-height: 44px;
  padding: 8px 14px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  width: 100%;
  color: var(--base-white, #FFF);
  /* Text sm/Medium */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  margin-top: 8px;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  .container {
    margin: 0 12px;
  }

  .buttonPrimary {
    font-size: 14px !important;
    padding: 8px 14px;
  }
}


.main {
  transition: 0.5s all;
  padding-top: 90px;
}

@media screen and (max-width: 1100px) {
  .main {
    padding-top: 72px;
  }
}

@media screen and (max-width: 768px) {
  .rowForm {
    flex-direction: column;
  }
}