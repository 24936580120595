.faqsContainer {
}

.questionsContainer {
  padding: 104px 0;
  max-width: 889px;
  margin: auto;
  position: relative;
  z-index: 2;
}

.circleSm {
  position: absolute;
  background-image: url('/v3/ellipse-sm.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  display: flex;
  aspect-ratio: 1 / 1;
  top: 0;
  transform: translateY(-50%);
  z-index: 0;
}

.questionsContainer .title {
  color: #1D2939;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
  letter-spacing: -0.72px;
  margin-bottom: 38px;
}

.questionsContainer .questionItem {
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--border-border-500, #D0D5DD);
  position: relative;
  z-index: 3;
  cursor: pointer;
  transition: all 0.2s;
}

.questionsContainer .questionItem .questionItemTitle {
  color: var(--text-text-500, #1D2939);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px; /* 150% */

  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 24px;
}

.questionsContainer .questionItem .questionItemTitle img {
  cursor: pointer;
}

.questionsContainer .questionItem .questionItemDesc {
  color: var(--text-text-500, #1D2939);
  /* Text md/regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  opacity: 0.7;
  margin-top: 10px;
  transition: all 0.2s;
  overflow: hidden;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.toolContainer {
  border-radius: 25px;
  border: 1px solid #EAECF0;
  background: rgba(255, 255, 255, 0.60);
  backdrop-filter: blur(12px);
  padding: 60px;
  margin: 124px 0;
  display: flex;
  align-items: center;
  position: relative;
}

.circleSm {
  position: absolute;
  background-image: url('/v3/ellipse-sm.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 90%;
  display: flex;
  aspect-ratio: 1 / 1;
  top: 100%;
  left: 100%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 0;
}

.circle {
  position: absolute;
  background-image: url('/v3/ellipse.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 90%;
  display: flex;
  aspect-ratio: 1 / 1;
  top: 0;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 0;
}

.toolContainer .toolContent {
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
}

/* .toolContainer .toolContent img {
  height: 48px
} */

.toolContainer .toolContent section div:nth-child(1) {
  color: var(--text-text-500, #1D2939);
  /* Text xl/Bold */
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 150% */
}

.toolContainer .toolContent section div:not(:nth-child(1)) {
  color: var(--text-text-400, #667085);
  /* Text md/regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}

.toolContainer .toolContent section div:nth-child(2) {
  margin: 12px 0 24px 0;
}

.toolContainer .toolIcon {
  flex: 1;
  position: relative;
}

.icon {
  width: 136px;
  height: 136px;
  position: relative;
}

.toolContainer .toolIcon div .icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.toolContainer .toolIcon div div:nth-child(1) {
  left: 0px;
  animation: identifierImg1 4s infinite;
}

.toolContainer .toolIcon div div:nth-child(2) {
  left: 80px;
  animation: identifierImg2 4s infinite;
}

.toolContainer .toolIcon div div:nth-child(3) {
  left: 160px;
  animation: identifierImg3 4s infinite;
}

.toolContainer .toolIcon div div:nth-child(4) {
  left: 240px;
  animation: identifierImg4 4s infinite;
}

.toolContainer .toolIcon div div:nth-child(5) {
  left: 320px;
  animation: identifierImg5 4s infinite;
}

.toolContainer .toolIcon div div:nth-child(6) {
  left: 400px;
  animation: identifierImg6 4s infinite;
}

.toolContainer .toolIcon .toolLogo {
  position: absolute;
  animation: identifier 4s infinite;
  width: 376px;
}

@keyframes identifierImg1 {
  0%, 20%, 80%, 100% {
    opacity: 1;
    left: 0px;
    transform: translateY(-50%) translateX(0%);
  }
  30%, 70% {
    opacity: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

@keyframes identifierImg2 {
  0%, 20%, 80%, 100% {
    opacity: 1;
    left: 80px;
    transform: translateY(-50%) translateX(0%);
  }
  30%, 70% {
    opacity: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

@keyframes identifierImg3 {
  0%, 20%, 80%, 100% {
    opacity: 1;
    left: 160px;
    transform: translateY(-50%) translateX(0%);
  }
  30%, 70% {
    opacity: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

@keyframes identifierImg4 {
  0%, 20%, 80%, 100% {
    opacity: 1;
    left: 240px;
    transform: translateY(-50%) translateX(0%);
  }
  30%, 70% {
    opacity: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

@keyframes identifierImg5 {
  0%, 20%, 80%, 100% {
    opacity: 1;
    left: 320px;
    transform: translateY(-50%) translateX(0%);
  }
  30%, 70% {
    opacity: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

@keyframes identifierImg6 {
  0%, 20%, 80%, 100% {
    opacity: 1;
    left: 400px;
    transform: translateY(-50%) translateX(0%);
  }
  30%, 70% {
    opacity: 0;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
}

@keyframes identifier {
  0%, 20%, 80%, 20%, 80%, 100% {
    left: -20%;
    transform: translateX(10%) translateY(-40%);
    opacity: 0;
  }
  30%, 70% {
    opacity: 1;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

@media screen and (max-width: 1100px) {
  .questionsContainer .title {
    color: #1D2939;
    text-align: center;
    /* Text md/Bold */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
    margin-bottom: 24px;
  }

  .questionsContainer .questionItem {
    padding: 12px;
  }

  .questionsContainer .questionItemDescOpacity {
    padding: 0 12px;
  }
  
  .questionsContainer .questionItem .questionItemTitle {
    color: var(--text-primary, #181B32);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .questionsContainer .questionItem .questionItemDesc {
    font-size: 12px;
  }

  .questionsContainer {
    padding: 60px 0;
  }

  .toolContainer {
    padding: 24px 16px;
    margin: 0 0 60px 0;
  }

  .toolContainer .toolContent section div:nth-child(1) {
    color: #1D2939;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 150% */
  }

  .toolContainer .toolContent section div:not(:nth-child(1)) {
    color: var(--text-text-400, #667085);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
  }

  .icon {
    width: 100px;
    height: 100px;
  }
  
  .toolContainer .toolIcon div div:nth-child(1) {
    left: 0px;
    animation: identifierImgMobile1 4s infinite;
  }
  
  .toolContainer .toolIcon div div:nth-child(2) {
    left: 60px;
    animation: identifierImgMobile2 4s infinite;
  }
  
  .toolContainer .toolIcon div div:nth-child(3) {
    left: 120px;
    animation: identifierImgMobile3 4s infinite;
  }
  
  .toolContainer .toolIcon div div:nth-child(4) {
    left: 180px;
    animation: identifierImgMobile4 4s infinite;
  }
  
  .toolContainer .toolIcon div div:nth-child(5) {
    left: 240px;
    animation: identifierImgMobile5 4s infinite;
  }
  
  .toolContainer .toolIcon div div:nth-child(6) {
    left: 300px;
    animation: identifierImgMobile6 4s infinite;
  }
  
  @keyframes identifierImgMobile1 {
    0%, 20%, 80%, 100% {
      opacity: 1;
      left: 0px;
      transform: translateY(-50%) translateX(0%);
    }
    30%, 70% {
      opacity: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  
  @keyframes identifierImgMobile2 {
    0%, 20%, 80%, 100% {
      opacity: 1;
      left: 60px;
      transform: translateY(-50%) translateX(0%);
    }
    30%, 70% {
      opacity: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  
  @keyframes identifierImgMobile3 {
    0%, 20%, 80%, 100% {
      opacity: 1;
      left: 120px;
      transform: translateY(-50%) translateX(0%);
    }
    30%, 70% {
      opacity: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  
  @keyframes identifierImgMobile4 {
    0%, 20%, 80%, 100% {
      opacity: 1;
      left: 180px;
      transform: translateY(-50%) translateX(0%);
    }
    30%, 70% {
      opacity: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  
  @keyframes identifierImgMobile5 {
    0%, 20%, 80%, 100% {
      opacity: 1;
      left: 240px;
      transform: translateY(-50%) translateX(0%);
    }
    30%, 70% {
      opacity: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }
  
  @keyframes identifierImgMobile6 {
    0%, 20%, 80%, 100% {
      opacity: 1;
      left: 300px;
      transform: translateY(-50%) translateX(0%);
    }
    30%, 70% {
      opacity: 0;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  .toolContainer .toolIcon .toolLogo {
    height: 250px;
  }
}

@media screen and (max-width: 900px) {
  .toolContainer {
    flex-direction: column;
    gap: 24px;
  }

  .toolContainer .toolIcon {
    width: 100%;
    flex: unset;
    display: flex;
  }

  .toolContainer .toolIcon div .icon {
    animation: unset !important;
    position: relative;
    top: unset;
    transform: unset;
    margin: 0 -54px;
  }

  .toolContainer .toolIcon .toolLogo {
    display: none;
  }

  .toolList {
    display: flex;
    justify-content: center;
  }

  .icon {
    width: 96px;
    height: 96px;
  }
}

@media screen and (max-width: 768px) {
  .circleSm, .circle {
    display: none;
  }
}